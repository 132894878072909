var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-body",
    [
      _c(
        "div",
        { staticClass: "image_crop_container_wrapper" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "vue-croppie",
                        _vm._b(
                          {
                            key: _vm.refCrop,
                            ref: "croppieRef",
                            attrs: {
                              "enable-orientation": "",
                              boundary: _vm.boundary,
                            },
                            on: {
                              result: _vm.handleResult,
                              update: _vm.update,
                            },
                          },
                          "vue-croppie",
                          _vm.cropOption,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { xl: "3" } }, [
                _c("figure", { staticClass: "figure cam_capture-figure" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.getCroppedPicture,
                      alt: "no picture cropped yet",
                    },
                  }),
                  _c(
                    "figcaption",
                    { staticClass: "figure-caption text-center" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Cropped picture")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { xl: "3" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-secondary mb-2",
                        block: "",
                        size: "md",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.bind()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "icon-action-undo" }),
                      _vm._v(
                        " " + _vm._s(_vm.FormMSG(3, "Undo crop")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-secondary",
                        block: "",
                        size: "md",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.rotate(-90)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "icon-reload" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.FormMSG(4, "Rotate picture")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm.hasValidationButton
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: _vm.cropped === "" },
                          on: { click: _vm.handleConfirmCrop },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(2, "Confirme Crop")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("loading", {
        attrs: { "is-full-page": "", active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }