<template>
	<!-- class="bg-secondary" -->
	<b-card-body>
		<div class="image_crop_container_wrapper">
			<b-row>
				<b-col xl="6">
					<b-form-group>
						<!-- v-bind:url="this.imgUrl" -->
						<!-- Note that 'ref' is important here (value can be anything). read the description about `ref` below. -->
						<vue-croppie
							ref="croppieRef"
							v-bind="cropOption"
							:key="refCrop"
							enable-orientation
							:boundary="boundary"
							@result="handleResult"
							@update="update"
						/>
						<!-- <b-button variant="info"   size="sm" @click="crop()"> <i class="icon-crop"></i> </b-button>  -->
					</b-form-group>
				</b-col>
				<b-col xl="3">
					<figure class="figure cam_capture-figure">
						<!--  <img :src="imgToDisplay" alt="no picture captured" style="width:300px;  border-radius: 15px; border: 1px solid #aaa; padding: 1px;" /> height="270"  -->
						<img :src="getCroppedPicture" alt="no picture cropped yet" />
						<figcaption class="figure-caption text-center">
							{{ FormMSG(1, 'Cropped picture') }}
						</figcaption>
					</figure>
				</b-col>
				<b-col xl="3">
					<b-button variant="outline-secondary mb-2" block size="md" @click="bind()">
						<i class="icon-action-undo" /> {{ FormMSG(3, 'Undo crop') }}
					</b-button>
					<b-button variant="outline-secondary" block size="md" @click="rotate(-90)">
						<i class="icon-reload" /> {{ FormMSG(4, 'Rotate picture') }}
					</b-button>
					<b-button v-if="hasValidationButton" style="width: 100%" :disabled="cropped === ''" @click="handleConfirmCrop">{{
						FormMSG(2, 'Confirme Crop')
					}}</b-button>
				</b-col>
			</b-row>
		</div>

		<loading is-full-page :active.sync="isLoading" />
	</b-card-body>
</template>

<script>
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import Loading from 'vue-loading-overlay';
// import { store } from '@/store';
import { isNil, isBase64 } from '@/shared/utils';
import { mergeIfObj } from '@/shared/helpers';
import mapProps from '@/shared/vuePropsMapper';

import languageMessages from '@/mixins/languageMessages';

import 'croppie/croppie.css'; // import the croppie css manually

Vue.use(VueCroppie);

export default {
	name: 'CropPicture',
	components: { Loading },
	mixins: [languageMessages],
	props: {
		...mapProps(['hasValidationButton', 'isPwaCapture'], {
			type: Boolean,
			required: false,
			default: false
		}),
		...mapProps(['baseUrlImg', 'options'], {
			type: Object,
			required: false,
			default: () => {}
		}),
		boundary: {
			type: Object,
			required: false,
			default: () => ({ width: 420, height: 240 })
		},
		type: {
			type: String,
			required: false,
			default: 'default',
			validator: (v) => ['default', 'avatar'].includes(v)
		}
	},
	data() {
		return {
			cropped: null,
			imgUrl: '',
			firstTime: true,
			isLoading: false,
			refCrop: 0,
			imgListIndex: null
		};
	},
	computed: {
		getCroppedPicture() {
			const src = isNil(this.cropped) ? this.baseUrlImg.img : this.cropped;
			return this.rendSrc(src);
		},

		/**
		 * @return {Object}
		 */
		cropOption() {
			// let res = {};
			//
			// if (this.type === 'avatar') {
			// 	res = {
			// 		...res,
   //        // url: this.rendSrc(this.baseUrlImg.img),
			// 		
			// 	};
			// }
			//
			// return res;
      const ratio = window.devicePixelRatio || 1
      return {
        quality: 1,
        format: 'jpeg',
        size: 'viewport',
        ...mergeIfObj(this.type === 'avatar', {
					circle: true,
					enableResize: false,
					viewport: {
            width: 200,
            height: 200,
            type: 'circle',
          },
        }),
        ...this.options,
      }
		}
	},
	created() {
		this.initCropImgList(this.baseUrlImg);
	},
	mounted() {
    this.$refs.croppieRef.bind({
      url: this.rendSrc(this.baseUrlImg.img)
    });
	},
	methods: {
    /**
     * @param {Object}
     */
		initCropImgList(crop) {
			if (isNil(crop)) return;
			this.imgListIndex = crop.index;
			this.refCrop = this.refCrop++;
		},

		bind() {
			this.$refs.croppieRef.bind({
				url: this.rendSrc(this.imgUrl),
			});
		},

		/**
		 * @param {String} img
		 * @return {String}
		 */
		rendSrc(img) {
			if (isNil(img)) return '';
			if (isBase64(img)) return img;
			const path = this.isPwaCapture ? '' : process.env.VUE_APP_PATH_IMG_URL;
			return path + img;
		},

		crop() {
			let options = { format: 'jpeg' };
			this.$refs.croppieRef.result(options, (output) => {
				this.cropped = output;
			  this.$emit('cropped', output);
			});
		},

		cropViaEvent() {
			this.$refs.croppieRef.result(options);
		},
		result(output) {
			this.cropped = output;
			this.$emit('cropped', img);
		},
		rotate(rotationAngle) {
			this.$refs.croppieRef.rotate(rotationAngle);
		},
		update() {
			this.crop();
		},

		handleConfirmCrop() {
			this.$emit('change', {
				isCropEnded: true,
				img: this.cropped,
				index: this.imgListIndex
			});
			this.cropped = null;
		},

		handleResult($event) {
      console.log({ handleResult: $event })
		}
	}
};
</script>
